<template>
  <MainLayout>
    <template #content>
      <div
        v-if="!IS_LOADING.status && WALLET"
        class="content-container w-full flex flex-col px-8"
      >
        <div class="flex flex-col w-full">
          <div id="downloadable" class="relative">
            <div class="absolute inset-0 z-10"></div>
            <VoucherCard
              ref="voucher"
              :cardId="`voucher-card-${$route.params.id}`"
              :voucher="getCustomVoucher(VOUCHER)"
              :order="VOUCHER.order"
              :qr="VOUCHER.qr"
              :userVoucher="VOUCHER"
              :role="'user'"
              :withQR="true"
              :isSpecialVoucher="getCustomVoucher(VOUCHER).isSpecialVoucher"
              :hideReminder="true"
            />
          </div>
      </div>
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from '_layouts';
import VoucherCard from '_components/List/Modules/VoucherList/VoucherDisplay/';
import moment from 'moment';
import { toPng } from 'html-to-image';
import { saveAs } from 'file-saver';

export default {
  name: 'Vouchers',
  components: {
    MainLayout,
    VoucherCard,
  },
  data() {
    return {
      location: window.location,
      currentButton: '',
      link: '',
      isShowMore: false,
      form: {
        id: null,
        voucher_id: null,
        user_id: null,
        value: null,
        qty: null,
        total_amount: 0,
      },
      emailForm: {
        id: null,
        email: '',
        subject: '',
        text: '',
        sent_via: 'email',
      },
      symbol: '',
      isAdded: false,
      options: {
        currentPage: 0,
        loop: true,
        infinite: 1,
        slidesToScroll: 1,
        pagination: false,
        centeredSlides: false,
      },
    };
  },
  computed: {
    VOUCHER() {
      return this.$store.getters.USER_VOUCHER;
    },
    WALLET() {
      return this.$store.getters.WALLET;
    },
    CARTS() {
      return this.$store.getters.CARTS;
    },
    AUTH_USER() {
      return this.$store.getters.AUTH_USER;
    },
    IS_LOADING() {
      return this.$store.getters.IS_LOADING;
    },
    months() {
      let filteredMonths = [];
      return this.WALLET.voucher && this.WALLET.voucher.valid_date
        ? this.WALLET.voucher.valid_date
            .filter((date) => {
              let month = date.start.split('-')[1];
              if (filteredMonths.indexOf(month) == -1) {
                filteredMonths.push(month);
                return true;
              }
              return false;
            })
            .map((date) => parseInt(moment(date.start).format('x')))
        : [];
    },
  },
  mounted() {
    
    (async () => {
      try {
        await this.$store.commit('SET_IS_LOADING', { status: 'open' });
        await this.onFetchVoucher();
        await this.$store.commit('SET_IS_LOADING', { status: 'close' });
        
        setTimeout(() => {
          toPng(document.getElementById('downloadable'))
          .then(function (dataUrl) {
            // console.log(dataUrl)
            // saveAs(dataUrl, `${Math.random().toString(36).slice(2, 7)}.png`);
            // window.open(dataUrl)

            var a = document.createElement("a"); //Create <a>
            a.href = dataUrl; //Image Base64 Goes here
            a.download = `${Math.random().toString(36).slice(2, 7)}.png`; //File name Here
            a.click();
          })
        }, 1000)

        setTimeout(() => {
          window.close()
        }, 20000)
      } catch (err) {
        await this.$store.commit('SET_IS_LOADING', { status: 'close' });
      }
    })();
  },
  methods: {
    getDay(day) {
      let long_day = {
        So: 'Sonntag',
        Mo: 'Montag',
        Di: 'Dienstag',
        Mi: 'Mittwoch',
        Do: 'Donnerstag',
        Fr: 'Freitag',
        Sa: 'Samstag',
      };
      return long_day[day];
    },
    getMonth(month) {
      return moment(+month)
        .lang('de')
        .format('MMMM');
    },
    getExpiration(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    getExpirationDefault(date) {
      return (
        '31.12.' +
        moment(date)
          .add()
          .format('YYYY')
      );
    },
    getCustomVoucher(row) {
      if (!row.order.voucher.data_json) {
        return row.order.voucher;
      }

      row.order.voucher.data_json = row.data_json;
      row.order.voucher.data_json.seller = row.order.voucher.seller;

      return row.order.voucher.data_json;
    },
    async onSubmit() {
      this.form.total_amount =
        this.form.value *
        (this.VOUCHER.type != 'quantity' ? 1 : this.VOUCHER.qty_val);
      this.$swal({
        title: 'Im Warenkorb hinzufügen',
        text: `Bist du sicher, dass du diesen Gutschein zum Warenkorb hinzufügen möchtest?`,
        showCancelButton: true,
        confirmButtonColor: '#48BB78',
        cancelButtonColor: '#FC8181',
        confirmButtonText: 'Bestätigen',
        cancelButtonText: 'Abbrechen',
      }).then(async (result) => {
        if (result.value) {
          try {
            await this.$store.commit('SET_IS_PROCESSING', { status: 'open' });
            this.form.total_amount =
              this.form.value *
              (this.VOUCHER.type != 'quantity' ? 1 : this.VOUCHER.qty_val);
            this.form.user_id = this.AUTH_USER.data.id;
            this.form.voucher_id = this.VOUCHER.id;
            if (this.VOUCHER.type == 'quantity') {
              this.form.qty = this.form.value;
              this.form.value = null;
            } else {
              this.form.value = this.form.value;
            }
            const data = await this.$store.dispatch('ADD_WALLET', this.form);
            this.form = {
              id: null,
              voucher_id: null,
              user_id: null,
              value: null,
              qty: null,
              value: 0,
              total_amount: 0,
            };
            await this.$store.commit('SET_IS_PROCESSING', { status: 'close' });
            let confirm = this.$swal({
              icon: 'success',
              title: 'Erfolgreich!',
              text: 'Die Gutscheine wurden in den Warenkorb gelegt.',
              allowOutsideClick: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              confirm.close();
              this.$router.push('/home');
            }, 1000);
          } catch (err) {
            await this.$store.commit('SET_IS_PROCESSING', { status: 'close' });
            this.$swal({
              icon: 'warning',
              title: 'Achtung! ',
              text:
                'Etwas ist schief gelaufen. Versuche es nochmal oder kontaktiere uns.',
              confirmButtonColor: '#48BB78',
              confirmButtonText: 'Bestätigen',
            });
          }
        }
      });
    },
    async onFetchVoucher() {
      try {
        await this.$store.dispatch('FETCH_USER_VOUCHER', this.$route.params.id);
        await this.$store.dispatch('FETCH_WALLET', this.VOUCHER.order_id);
      } catch (err) {
        console.log('err', err);
      }
    },
  },
};
</script>
<style lang="css" scoped>
.show-more {
  -webkit-mask-image: -webkit-gradient(
    linear,
    center bottom,
    center top,
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(1, rgba(0, 0, 0, 1))
  );
}
.input-copy {
  color: rgba(0, 0, 0, 0.75);
  -webkit-appearance: none;
  background-color: #f7f7f7;
  border-width: 1px;
  border-color: #00000033;
  border-radius: 0.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.order__form-number {
  cursor: pointer;
  width: 12px;
  height: 12px;
  text-align: center;
  color: #333;
  font-weight: bold;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.order__form .order__form-number.order-up {
  position: absolute;
  top: 10px;
  right: 4px;
}
.order__form .order__form-number.order-down {
  position: absolute;
  top: 10px;
  left: 20px;
}
.order__form-group {
  width: 80px;
  position: relative;
}
.order__form input[type='number']::-webkit-inner-spin-button,
.order__form input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.order__form input[type='number'] {
  -moz-appearance: textfield;
  width: 100%;
  padding-right: 5px;
  text-align: center;
  color: #45434a;
  padding-top: 7px;
  padding-bottom: 8px;
  border-radius: 8px;
  border: 2px solid #e3e3e3;
}
.order__form input[type='number']:focus {
  outline: 0;
}
.company-color {
  color: var(--company-color, var(--card-description-background, #ff5563));
}
.company-bg-color {
  background: var(--company-color, var(--card-description-background, #ff5563));
}
</style>
